.splash-page-default-style {
    color: #000000;
    background-color: #ffffff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.mobile-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.mobile-popup {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    width: 300px;
    text-align: center;
    z-index: 10000;
}

.popup-message {
    margin-bottom: 20px;
}

.mobile-close-button {
    margin-top: 16px;
    font-size: 16px;
    height: 50px;
    margin-bottom: 0;
    background-color: #0D0E10;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
    text-decoration: none;
    transition: background-color 150ms, color 150ms ease-in-out;
    white-space: nowrap;
}

.mobile-close-button:hover {
    background-color: #ff5263;
    color: black;
}

@media (max-width: 768px) {
    .mobile-popup {
        width: 90%;
    }
}
