.child-news-feed-comment-user-info-container {
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}

.grandchild-news-feed-comment-user-info-container {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 490px;
  flex: 1;
}

.comment-owner-avatar-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  position: static;
}

.comment-news-feed-owner-avatar {
  border: none
}

.comment-news-feed-owner-avatar-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
  overflow: clip;
}


.news-feed-comment-owner-name-container {
  display: flex;
  align-items: center;
  max-width: 440px;
  box-sizing: border-box;
  white-space: nowrap;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(0, 0, 0);
  margin-left: 3%;
}

.news-feed-comment-owner-name {
  color: #000000;
  margin-left: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: transparent;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

.news-feed-comment-neighborhood-name-container {
  color: #666666;
  letter-spacing: -0.32px;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  white-space: pre-wrap;
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
}

.news-feed-comment-neighborhood-name {
  font-size: 14px;
  color: #6f7681;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.news-feed-comment-owner-name-and-neighborhood-container {
  display: inline-block;
}

.news-feed-comment-neighborhood-name-container::before {
  content: ' \2022 '; /* add bullet character */
  display: inline-block;
  margin-right: 5px; /* add space between the bullet and the text */
}


/* Popup container */
.news-feed-comment-user-profile-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;
  width: calc(300px + #{$popup-news-feed-post-user-name}); /* this seems to help */
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 8%;
}

.sub-news-feed-comment-user-profile-popup {
  display: flex;
}

.popup-news-feed-comment-user-avatar-image {
  height: 120px;
  aspect-ratio: auto 120 / 120;
  width: 120px;
  border: 0;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.popup-news-feed-comment-user-info-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 8%;
}

.popup-news-feed-comment-user-name {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Lato, "Lucida Grande", Tahoma, sans-serif;
  font-size: 34px;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1;
  z-index: 2;
}

.popup-news-feed-comment-neighborhood-name {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(102, 102, 102);
}

.popup-news-feed-comment-user-bio-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  min-height: 0px;
  min-width: 0px;
  max-height: 100%;
  max-width: 100%;
  line-height: 0;
  margin-bottom: 16px;
  display: block;
  margin-top: 5%;
  margin-left: 2%;
}

.popup-news-feed-comment-user-bio {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(102, 102, 102);
  overflow-wrap: break-word;
  white-space: normal;
}

.news-feed-comment-delete-edit-dropdown-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  margin-right: 0px;
}

.sub-news-feed-comment-delete-edit-dropdown-container {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  display: inline-flex;
  color: rgb(0, 0, 0);
}

.news-feed-comment-delete-edit-dropdown-icon {
  overflow: hidden;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: inline;
  color: rgb(0, 0, 0);
  width: 24;
  height: 24;
  fill: none;
}

.news-feed-comment-delete-edit-dropdown-menu-container {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.news-feed-comment-delete-edit-dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 4px;
  min-width: 100px;
}

.news-feed-comment-delete-edit-dropdown-item {
  color: #333;
  cursor: pointer;
  padding: 8px 12px;
  white-space: nowrap;
}

.news-feed-comment-delete-edit-dropdown-item:hover {
  background-color: #f5f5f5;
}
