.news-feed-post-body {
  padding-left: 16px;
}

.news-feed-post-content {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.32px;
  -webkit-font-smoothing: antialiased;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.news-feed-post-img {
  height: auto;
  width: 100%;
  cursor: pointer;
}

/* image modal */

.news-feed-post-body-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-feed-post-body-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.news-feed-post-body-modal-img {
  max-width: 100%;
  max-height: 100%;
}

.news-feed-post-body-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  padding: 5px;
  margin-top: 20%;
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  border-radius: 100vh;
  padding: 8px;
  z-index: 1050;
}

.news-feed-post-body-modal-close:hover {
  outline: 2px solid green;
}
