.hero {
  height: 900px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 16px;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
  display: block;
  z-index: 0; /* this makes sure that the image is behind the signup box */
  box-sizing: border-box;
}

.hero-img {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/header.jpg');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: block;
  z-index: -1; /* this makes sure that the image is behind the signup box */
  box-sizing: border-box;
}


/* Everything beneth here has to do with the sign up box in the center of the page */

.hero-info-box {
  max-width: 445px;
  text-align: left;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-sizing: border-box;
}

.hero-signup-box {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  min-width: 400px;
}

.hero-login-box {
  text-align: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  color: #333333;
  display: block;
  box-sizing: border-box;
}

.sub-hero-signup-box {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 12px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: block;
  box-sizing: content-box;
  color: var(--fg-primary);
  margin: 20px 0 20px 0;
  transition: opacity 0.5s;
  height: 100%;
  overflow: hidden;
}

.sub-hero-signup-box-content {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

}

.sub-hero-signup-box-content-title {
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  max-width: 100%;
  margin: 0px 0px 24px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.sub-hero-signup-box-content-choose-method {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    box-sizing: border-box;
    margin-block: 0px;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
}

.thrid-party-options {
  display: block;
  box-sizing: border-box;
}

.social-registration {
  margin-bottom: 12px;
  display: block;
  box-sizing: border-box;
}

.social-registration-company {
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(102, 102, 102);
  cursor: pointer;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 12px;
  font-size: 17px;
  line-height: 24px;
  padding: 12px 20px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: center;
}

.social-registration-company > img {
  vertical-align: middle;
  border: 0;
  height: 20px;
  aspect-ratio: auto 20 / 20;
  width: 20px;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.social-registration-label {
  flex-grow: 1;
  display: block;
  box-sizing: border-box;
}

.divider::before {
  border-top: 1px solid #949494;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  margin: 10px auto 0;
  box-sizing: border-box;
}

.divider {
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
  display: block;
  box-sizing: border-box;
}

.or {
  padding-top: 24px;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  display: block;
}

.or > span {
  color: #6f7681;
  text-align: center;
  padding-left: 5px;
  padding-top: 3px;
  padding-right: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
}

.email-password {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 0em;
}

.signup-errors {
  color: red;
}

.sub-email-password {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 0em;
}

.email-input {
  padding-bottom: 16px;
  box-sizing: border-box;
  display: block;
}

.sub-email-input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  width: auto;
}

.deep-sub-email-input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;
  height: 56px;
  display: block;
}

.actual-email-input {
  animation-name: onAutoFillCancel;
  animation-duration: 0.001s;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(229, 229, 229);
  color: rgb(0, 0, 0);
  border: none;
  padding: 22px 16px 8px;
  box-sizing: border-box;
  height: 56px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
}

.email-input-label {
  z-index: 1;
  position: absolute;
  top: -4px;
  left: 4px;
  pointer-events: none;
  transform-origin: left top;
  transform: translate(12px, 20px) scale(1);
  color: rgb(102, 102, 102);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  box-sizing: border-box;
  cursor: default;
}


.password-input {
  padding-bottom: 16px;
  box-sizing: border-box;
  display: block;
}

.sub-password-input {
  width: auto;
  box-sizing: border-box;
  display: block;
}

.deep-sub-password-input {
  position: relative;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: block;
}

.actual-password-input {
  animation-name: onAutoFillCancel;
  animation-duration: 0.001s;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(229, 229, 229);
  color: rgb(0, 0, 0);
  border: none;
  padding: 22px 16px 8px;
  box-sizing: border-box;
  height: 56px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
}

.password-input-label {
  z-index: 1;
  position: absolute;
  top: -4px;
  left: 4px;
  pointer-events: none;
  transform-origin: left top;
  transform: translate(12px, 20px) scale(1);
  color: rgb(102, 102, 102);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  cursor: default;
}

.signup-continue-button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: block;
}

.signup-continue-button-style {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  border-radius: 12px;
  font-size: 17px;
  line-height: 24px;
  padding: 12px 20px;
  margin-top: 24px;
  width: 100%;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}

.signup-disclamier {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 24px;
  margin: 0 0 10px;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-top: 24px;
}

.signup-disclamier-content {

  /* color: #007b99; */
  text-decoration: none;
  background-color: transparent;
  /* cursor: pointer; */
}

.have-a-business-prompt {
  font-size: 12px;
  color: #333333;
  text-align: center;
  padding-top: 22px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  display: block;
}

.have-a-business-prompt-label {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.have-a-business-prompt-label-link {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(0, 118, 182);
  cursor: pointer;
  text-decoration: none;
  padding-left: 4px;
  background-color: transparent;
}

.have-an-account-prompt {
  font-size: 12px;
  color: #333333;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  padding-bottom: 16px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  display: block;
}

.have-an-account-prompt-label {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.have-an-account-prompt-label-link {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(0, 118, 182);
  cursor: pointer;
  text-decoration: none;
  padding-left: 4px;
  background-color: transparent;
}
