.news-feed-user-content {
  height: 100vh;
  width: calc(26% - 20px);
  margin-bottom: 20px;

}

.parent-news-feed-create-event-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: none;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  width: 280px;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.child-news-feed-create-event-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block-start: 0px;
  margin-block-end: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  background-blend-mode: darken;
  object-fit: cover;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: url(https://d19rpgkrjeba2z.cloudfront.net/static/images/local_events/rhr_local_events_banner.png) center center / cover no-repeat, rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
}

.news-feed-create-event-container-title {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Lato, "Lucida Grande", Tahoma, sans-serif;
  font-size: 34px;
  font-weight: 900;
  line-height: 40px;
  font-style: italic;
  color: rgb(255, 255, 255);
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  overflow-wrap: break-word;
  hyphens: auto;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.news-feed-create-event-container-button{
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 16px;
  display: block;
  width: 90%;
  margin-top: 24px;
  margin-bottom: 24px;
  text-transform: none;
  overflow: visible;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}

.parent-news-feed-create-business-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background-color: #ffffff;
  width: 280px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: none;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  color: rgb(0, 0, 0);
  margin-top: 10px;
}

@media screen and (min-width: 480px){
  .parent-news-feed-create-business-container {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
      border-radius: 8px;
      margin-bottom: 8px;
      border: none;
  }
}

.parent-news-feed-create-business-img-box {
  padding-top: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f9fff0;
  width: 100%;
}

.child-news-feed-create-business-img-box {
  background-color: #f9fff0;
  text-align: center;
}

.news-feed-create-business-img {
  width: 80%;
  vertical-align: middle;
  border: 0;
  overflow: clip;
}

.news-feed-create-business-title-container {
  padding: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

.news-feed-create-business-title {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.news-feed-create-business-description {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 0px 16px;
  margin: 0 0 10px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.news-feed-create-business-button {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  font-size: 15px;
  line-height: 20px;
  padding: 8px 16px;
  display: block;
  width: 100%;
  border-radius: 100px;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}

.parent-news-feed-neighbors-reccomendations-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
  width: 280px;
}

.child-news-feed-neighbors-reccomendations-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: none;
}

.grandchild-news-feed-neighbors-reccomendations-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  padding-left: 6px;
  padding-top: 18px;
  padding-bottom: 12px;
}

.news-feed-neighbors-reccomendations-title-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-right: 3%;
  margin-bottom: 10px;
}

.news-feed-neighbors-reccomendations-title {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: Lato, "Lucida Grande", Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  font-style: italic;
  margin-left: 12px;
  margin-top: 10px;

  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-left: 7%;
}

.parent-single-neighbor-reccomendation-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  padding: 12px;
  display: block;
}

.child-single-neighbor-reccomendation-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
}

.single-neighbor-reccomendation-avatar-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  min-height: 0px;
  min-width: 0px;
  max-height: 100%;
  max-width: 100%;
  line-height: 0;
  margin-right: 12px;
}

.single-neighbor-reccomendation-avatar {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  width: 36px;
  height: 36px;
  object-fit: cover;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  border: 0;
  overflow: clip;
}

.parent-single-neighbor-reccomendation-info-container {
  min-height: 0px;
  min-width: 0px;
  max-height: 100%;
  max-width: 100%;
  margin-right: 12px;
  flex: 1 1 0%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: block;
}

.child-single-neighbor-reccomendation-info-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  width: 100%;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.single-neighbor-reccomendation-name-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  min-height: 0px;
  min-width: 0px;
  max-height: 100%;
  max-width: 100%;
  line-height: 0;
  margin-right: 0px;
  display: block;
}

.single-neighbor-reccomendation-name {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium",Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: 5px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: block;
}

.single-neighbor-reccomendation-neighborhood-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.single-neighbor-reccomendation-neighborhood-icon {
  overflow: hidden;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: inline;
  vertical-align: middle;
  width: 16;
  height: 16;
  fill: none;
  color: #666666;

}

.single-neighbor-reccomendation-neighborhood-text {
  color: #666666;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
}

.single-neighbor-reccomendation-button-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: row;
  gap: 12px;
  -webkit-box-align: center;
  align-items: center;
}

.single-neighbor-reccomendation-button {
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  border-radius: 100vh;
  padding: 8px 16px;
  box-sizing: content-box;
  width: 56.2207px;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}
