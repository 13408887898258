.login-form-logo-container{
  position: fixed;
  top: 10px;
  left: 10px;
  width: 350px;
  height: 40px;
  overflow: hidden;
}

.login-form-logo {
  width: 350px;
  height: 40px;

}

.login-info-box {
  max-width: 445px;
  text-align: left;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-sizing: border-box;
}

.login-signup-box {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  min-width: 400px;
}

.login-login-box {
  text-align: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  color: #333333;
  display: block;
  box-sizing: border-box;
}

.sub-login-signup-box {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 12px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Roboto', 'Segoe UI', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: block;
  box-sizing: content-box;
  color: var(--fg-primary);
  margin: 20px 0 20px 0;
  transition: opacity 0.5s;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.sub-login-signup-box-content {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;

}

.sub-login-signup-box-content-title {
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  max-width: 100%;
  margin: 0px 0px 24px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.sub-login-signup-box-content-choose-method {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    box-sizing: border-box;
    margin-block: 0px;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
}

.login-form-thrid-party-options {
  display: block;
  box-sizing: border-box;
}

.login-form-social-registration {
  margin-bottom: 12px;
  display: block;
  box-sizing: border-box;
}

.login-form-social-registration-company {
  display: block;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(102, 102, 102);
  cursor: pointer;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 12px;
  font-size: 17px;
  line-height: 24px;
  padding: 12px 20px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: center;
}

.login-form-social-registration-company > img {
  vertical-align: middle;
  border: 0;
  height: 20px;
  aspect-ratio: auto 20 / 20;
  width: 20px;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.login-form-social-registration-label {
  flex-grow: 1;
  display: block;
  box-sizing: border-box;
}

.login-form-divider::before {
  border-top: 1px solid #949494;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  margin: 10px auto 0;
  box-sizing: border-box;
}

.login-form-divider {
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
  display: block;
  box-sizing: border-box;
}

.login-form-or {
  padding-top: 24px;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  display: block;
}

.login-form-or > span {
  color: #6f7681;
  text-align: center;
  padding-left: 5px;
  padding-top: 3px;
  padding-right: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
}

.login-form-email-password {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 0em;
}

.login-form-sub-email-password {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 0em;
}

.login-form-email-input {
  padding-bottom: 16px;
  box-sizing: border-box;
  display: block;
}

.login-form-sub-email-input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  width: auto;
}

.login-form-form-errors {
  color: red;
}

.login-form-deep-sub-email-input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  width: 100%;
  height: 56px;
  display: block;
}

.login-form-actual-email-input {
  animation-name: onAutoFillCancel;
  animation-duration: 0.001s;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(229, 229, 229);
  color: rgb(0, 0, 0);
  border: none;
  padding: 22px 16px 8px;
  box-sizing: border-box;
  height: 56px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
}

.login-form-email-input-label {
  z-index: 1;
  position: absolute;
  top: -4px;
  left: 4px;
  pointer-events: none;
  transform-origin: left top;
  transform: translate(12px, 20px) scale(1);
  color: rgb(102, 102, 102);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  box-sizing: border-box;
  cursor: default;
}


.login-form-password-input {
  padding-bottom: 16px;
  box-sizing: border-box;
  display: block;
}

.login-form-sub-password-input {
  width: auto;
  box-sizing: border-box;
  display: block;
}

.login-form-deep-sub-password-input {
  position: relative;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: block;
}

.login-form-actual-password-input {
  animation-name: onAutoFillCancel;
  animation-duration: 0.001s;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(229, 229, 229);
  color: rgb(0, 0, 0);
  border: none;
  padding: 22px 16px 8px;
  box-sizing: border-box;
  height: 56px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
}

.login-form-password-input-label {
  z-index: 1;
  position: absolute;
  top: -4px;
  left: 4px;
  pointer-events: none;
  transform-origin: left top;
  transform: translate(12px, 20px) scale(1);
  color: rgb(102, 102, 102);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  cursor: default;
}

.login-form-signup-continue-button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: block;
}

.login-form-signup-continue-button-2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  display: block;
  padding-top: 16px;
}

.login-form-signup-continue-button-style {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  appearance: none;
  border: 0.5px solid rgb(184, 236, 81);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  transition: background 50ms ease 0s, color 50ms ease 0s, border-color 50ms ease 0s;
  background-color: rgb(184, 236, 81);
  color: rgb(0, 97, 66);
  border-radius: 12px;
  font-size: 17px;
  line-height: 24px;
  padding: 12px 20px;
  margin-top: 24px;
  width: 100%;
  text-transform: none;
  overflow: visible;
  margin: 0;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
}

.new-to-neighborhood-node-prompt {
  font-size: 12px;
  color: #333333;
  text-align: center;
  padding-top: 22px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue, "Helvetica Neue", Helvetica, Roboto, "Segoe UI", Arial, sans-serif;
  display: block;
}

.new-to-neighborhood-node-prompt-label {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.new-to-neighborhood-node-prompt-label-link {
  box-sizing: border-box;
  margin-block: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, HelveticaNeue-Medium, "Helvetica Neue Medium", Helvetica-Bold, Roboto-Medium, "Segoe UI Semibold", Arial-Bold, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(0, 118, 182);
  cursor: pointer;
  text-decoration: none;
  padding-left: 4px;
  background-color: transparent;
}
